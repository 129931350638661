(function() {
    'use strict';

    angular.module('hxPOSAdmin')
          .controller('adminProductInvEditCtrl', adminProductInvEditCtrl);

    adminProductInvEditCtrl.$inject=['$state','AdminService','$scope','hxIsProcessingService'];
    function adminProductInvEditCtrl($state, AdminService, $scope, hxIsProcessingService){
      var $ctrl=this;
      var service=AdminService;

      console.log('adminCatEditCtrl OK!');
      $ctrl.productEditCreate=function(obj){
        console.log('Create new product');
        $scope.$emit('is_Processing');
        service.productInvAPI.post(obj).then(function(response){
          $scope.$emit('done_Processing');
        });
        
        /**
        *Update stock with 0 quantity
        **/
       var submissionDate = new Date();
        var sendObj = {
          "staff": "Admin", //Default
          "categoryName": "Others", //Default
          "productName": obj.productName,
          "InOut": "IN",
          "quantity": 0 ,
          "date": submissionDate.getTime()
       };
       $scope.$emit('is_Processing');
       service.inventoryApi.post(sendObj).then(function(response){
          $scope.$emit('done_Processing');
          $state.go('admin.inventory');
      });
    }
      $ctrl.productEditUpdate=function(id,obj){
          console.log('Update product');
          $scope.$emit('is_Processing');
          service.productInvAPI.put(id,obj).then(function(response){
            $scope.$emit('done_Processing');
            $state.go('admin.inventory');
          });

          /**
          *Update stock with 0 quantity
          **/
          var submissionDate = new Date();
          var sendObj = {
            "staff": "Admin", //Default
            "categoryName": "Others", //Default
            "productName": obj.productName,
            "InOut": "IN",
            "quantity": 0 ,
            "date": submissionDate.getTime()
          };
          $scope.$emit('is_Processing');
          service.inventoryApi.post(sendObj).then(function(response){
            $scope.$emit('done_Processing');
            $state.go('admin.inventory');
          });
      };
    }
})();

/**
 * Created by sanolab on 2017/06/11.
 */
(function() {
    'use strict';

    angular.module('groupChart')
        .config(routeConfig);

    /**
     * Configures the routes and views
     */
    routeConfig.$inject = ['$stateProvider'];
    function routeConfig ($stateProvider) {
        // Routes
        $stateProvider
            .state('Chart', {
                abstract: true,
                templateUrl: 'src/Chart/Chart.html'
            })
            // .state('stati.ordersDisplay', {
            //     url: '/',
            //     templateUrl: 'src/Payment/ordersDisplay.html',
            //     resolve:{
            //         isReady: ['appPaymentDataService',function(appPaymentDataService){
            //             return appPaymentDataService.initializeListOfOrders();
            //         }]
            //     },
            //     controller:'ordersDisplayController',
            //     controllerAs:'$order_ctrl'
            // })
            //

                // *******************************
            // .state('Chart.lineChart',{
            //     url:'/Chart/lineChart',
            //     template:'<line-chart number ="2"></line-chart>'
            //     // controller:'lineChartController',
            //     // controllerAs:'$ctrl'
            // })

                .state('Chart.chartDisplay',{
                url:'/Chart/chartDisplay',
                templateUrl:'src/Chart/chartDisplay.html',
                controller:'chartDisplayController',
                controllerAs:'$ctrlChart'
             });

    }
})();

(function() {
    'use strict';

    angular.module('hxPOSAdmin')
          .controller('adminProductEditCtrl', adminProductEditCtrl);

    adminProductEditCtrl.$inject=['$state','AdminService','$scope','hxIsProcessingService'];
    function adminProductEditCtrl($state, AdminService, $scope, hxIsProcessingService){
      var $ctrl=this;
      var service=AdminService;

      console.log('adminCatEditCtrl OK!');
      $ctrl.productEditCreate=function(obj){
        console.log('Create new product');
        $scope.$emit('is_Processing');
        service.productAPI.post(obj).then(function(response){
          $scope.$emit('done_Processing');
          $state.go('admin.product');
        });
      };

      $ctrl.productEditUpdate=function(id,obj){
          console.log('Update product');
          $scope.$emit('is_Processing');
          service.productAPI.put(id,obj).then(function(response){
            $scope.$emit('done_Processing');
            $state.go('admin.product');
          });
      };

      // $ctrl.productEditRemove=function(id,obj){
      //     console.log('Update product');
      //     console.log('Remove object ID: '+id);
      //   //TODO: Call service to update product;
      // };
    }
})();

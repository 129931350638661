(function(){
    "use strict";

    angular.module('hxAdminComponents')
        .component('orderDisplayByTime',{
            templateUrl:'src/hxAdminComponents/orderDisplayByTime.html',
            controller:orderDisplayByTime,
            bindings:{
                time:'<'
            }
        });

    orderDisplayByTime.$inject=['$http','hxIsProcessingService','$scope','$state'];
    function orderDisplayByTime($http,hxIsProcessingService, $scope, $state){
        console.log("orderDisplayByTime component ok!");
        var $ctrl=this;

        const appPaymentApiPath="/api/facebookOrder";

        $ctrl.$onChanges=function(changesObj){
          var time=changesObj.time.currentValue;
          $ctrl.orders=[];
          var startCreatedTime=new Date(time.setHours(0,0,0)).toISOString();
          var nextday = new Date(time.setHours(0,0,0)+1000*60*60*24).toISOString();
          console.log(nextday);

          $scope.$emit('is_Processing');
          $ctrl.onStatistic=false;
          $http.get(appPaymentApiPath+'/orderByTime?startTime='+
              startCreatedTime+"&endTime="+nextday).then(function(response){
              //Pass response data to list//
              console.log({responseData:response.data});
              $scope.$emit('done_Processing');

              //Sort response data by PLACE//
              response.data.sort(function(a,b){
               return a.pickUp.place.localeCompare(b.pickUp.place);
              });
              console.log(response.data);

              //Get total Price//
              $ctrl.orders=response.data.map(function(obj){
                var newObj={};
                var displayTime=new Date(obj.pickUp.startTime);
                newObj=obj;

                newObj.priceList=obj.listProduct.map(function(e){
                  return (e.quantity*e.price);
                });
                newObj.totalPrice=newObj.priceList.reduce(function(a,b){
                  return a+b;
                },0);
                newObj.displayTime=displayTime.toUTCString();
                return newObj;
              });
         });
        };

        console.log($ctrl.orders);

        $ctrl.delete=function(item){
          console.log('Start delete!');
          item.isDeleted=true;
          $scope.$emit('is_Processing');
          callEditHttp(item);
        };

        $ctrl.update=function(item){
          console.log('In update!');
          $scope.$emit('is_Processing');
          callEditHttp(item);
        };

        function callEditHttp(item){
          console.log('in CallEditHttp');
          var id=item._id;
          var body=angular.toJson(item);
          $http.post(appPaymentApiPath+"/"+id+"/edit",body).then(function(response){
              //Pass response data to list//
              console.log({response:response});
              $scope.$emit('done_Processing');
              $state.reload();
            },function(error){
              console.log({error:error});
              warning(error.statusText);
              return error;
            });
        }
    }
})();

(function() {
    'use strict';

    angular.module('hxPOSAdmin')
        .config(routeConfig);

    /**
     * Configures the routes and views
     */
    routeConfig.$inject = ['$stateProvider','jwtOptionsProvider','$httpProvider'];
    function routeConfig ($stateProvider,jwtOptionsProvider,$httpProvider) {
        // Routes
        $stateProvider
            .state('admin', {
                abstract: true,
                templateUrl: 'src/admin/admin.html'
            })
            // Contains state that all authenticated states inherit
            .state('admin.topPage', {
                url: '/',
                templateUrl: 'src/admin/topPage.html',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.category', {
                url: '/category',
                templateUrl: 'src/admin/category.html',
                resolve:{
                  category:['AdminService', function(AdminService){
                      var query="/api/collection?isAvailable=true";
                      return AdminService.catsAPI.get(query);
                  }]
                },
                controller:'adminCatCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.categoryAdd', {
                url: '/category/add',
                template: '<hx-cat-management on-create="$ctrl.catEditCreate(obj);" list-products="$resolve.listProducts"></hx-cat-management>',
                resolve:{
                  listProducts:['AdminService', function(AdminService){
                      return AdminService.productAPI.get();
                  }]
                },
                controller:'adminCatEditCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.categoryEdit',{
                url:'/category/edit',
                template:'<hx-cat-management in-obj="$resolve.editObj" list-products="$resolve.listProducts" on-update="$ctrl.catEditUpdate(id,obj);" on-remove="$ctrl.catEditRemove(id);"></hx-cat-management>',
                resolve:{
                  editObj:['AdminService', function(AdminService){
                      return AdminService.editCategory;
                  }],
                  listProducts:['AdminService', function(AdminService){
                      return AdminService.productAPI.get();
                  }]
                },
                controller:'adminCatEditCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.product', {
                url: '/product',
                templateUrl: 'src/admin/product.html',
                resolve:{
                  products:['AdminService', function(AdminService){
                      return AdminService.productAPI.get();
                  }]
                },
                controller:'adminProductCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.productAdd', {
                url: '/product/add',
                template: '<hx-product-management on-create="$ctrl.productEditCreate(obj);"></hx-product-management>',
                controller:'adminProductEditCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.productEdit',{
                url:'/product/edit',
                template:'<hx-product-management in-obj="$resolve.editObj" on-update="$ctrl.productEditUpdate(id,obj);"></hx-product-management>',
                resolve:{
                  editObj:['AdminService', function(AdminService){
                      return AdminService.editProduct;
                  }]
                },
                controller:'adminProductEditCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            /** 
            * Inventory route
            **/
            .state('admin.inventory', {
                url: '/inventory',
                templateUrl: 'src/admin/inventory.html',
                resolve:{
                    products:['AdminService', function(AdminService){
                        return AdminService.productInvAPI.get();
                    }],
                    category:['AdminService', function(AdminService){
                        var query="/api/collectionInventory?isAvailable=true";
                         return AdminService.catsInvAPI.get(query);
                    }],
                    stocks:['AdminService', function(AdminService){
                        var query="/api/inventory/stock";
                        return AdminService.inventoryApi.get(query);
                    }]
                },
                controller:'adminInvCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            /** 
            * Product Inventory route
            **/
           .state('admin.productInvAdd', {
                url: '/inventory/product/add',
                template: '<hx-product-management on-create="$ctrl.productEditCreate(obj);"></hx-product-management>',
                controller:'adminProductInvEditCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.productInvEdit',{
                url:'/inventory/product/edit',
                template:'<hx-product-management in-obj="$resolve.editObj" on-update="$ctrl.productEditUpdate(id,obj);"></hx-product-management>',
                resolve:{
                editObj:['AdminService', function(AdminService){
                        return AdminService.editInvProduct;
                    }]
                },
                controller:'adminProductInvEditCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            /** 
            * Category Inventory route
            **/
           .state('admin.categoryInvAdd', {
                url: '/inventory/category/add',
                template: '<hx-cat-management on-create="$ctrl.catEditCreate(obj);" list-products="$resolve.listProducts"></hx-cat-management>',
                resolve:{
                listProducts:['AdminService', function(AdminService){
                    return AdminService.productInvAPI.get();
                }]
                },
                controller:'adminCatInvEditCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.categoryInvEdit',{
                url:'/inventory/category/edit',
                template:'<hx-cat-management in-obj="$resolve.editObj" list-products="$resolve.listProducts" on-update="$ctrl.catEditUpdate(id,obj);" on-remove="$ctrl.catEditRemove(id);"></hx-cat-management>',
                resolve:{
                editObj:['AdminService', function(AdminService){
                    return AdminService.editInvCategory;
                }],
                listProducts:['AdminService', function(AdminService){
                    return AdminService.productInvAPI.get();
                }]
                },
                controller:'adminCatInvEditCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            /** 
            * Statistic route
            **/
            .state('admin.statistic', {
                url: '/product/statistic',
                templateUrl: 'src/admin/statistic.html',
                controller:'simpleStatisticsController',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.translation', {
                url: '/translation',
                templateUrl: 'src/admin/translation.html',
                resolve:{
                  listTranslations:['AdminService', function(AdminService){
                      return AdminService.transAPI.get();
                  }],
                  listItems:['AdminService',function(AdminService){
                      return AdminService.productAPI.get();
                  }]
                },
                controller:'adminTransCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.translationcats', {
                url: '/translationcats',
                templateUrl: 'src/admin/translation.html',
                resolve:{
                  listTranslations:['AdminService', function(AdminService){
                      return AdminService.transAPI.get();
                  }],
                  listItems:['AdminService',function(AdminService){
                      return AdminService.catsAPI.get();
                  }]
                },
                controller:'adminTransCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.translationInv', {
                url: '/translationInv',
                templateUrl: 'src/admin/translation.html',
                resolve:{
                  listTranslations:['AdminService', function(AdminService){
                      return AdminService.transAPI.get();
                  }],
                  listItems:['AdminService',function(AdminService){
                      return AdminService.productInvAPI.get();
                  }]
                },
                controller:'adminTransCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            .state('admin.translationInvcats', {
                url: '/translationInvcats',
                templateUrl: 'src/admin/translation.html',
                resolve:{
                  listTranslations:['AdminService', function(AdminService){
                      return AdminService.transAPI.get();
                  }],
                  listItems:['AdminService',function(AdminService){
                      return AdminService.catsInvAPI.get();
                  }]
                },
                controller:'adminTransCtrl',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
            })
            // .state('admin.facebookOrder',{
            //   url: '/facebookOrder',
            //   templateUrl: 'src/admin/facebookOrder.html',
            //   controller:'facebookOrderController',
            //   controllerAs:'$ctrl',
            //   onEnter: ['$transition$',checkAuthentication]
            // })
            .state('admin.delOrder',{
                url: '/delOrder',
                templateUrl: 'src/admin/delOrder.html',
                controller:'delOrderController',
                controllerAs:'$ctrl',
                onEnter: ['$transition$',checkAuthentication]
              })
            .state('admin.login', {
                url: '/login',
                template: '<hx-login-form next-state="admin.topPage"></hx-login-form>',
            })
        ;

        jwtOptionsProvider.config({
            tokenGetter: function() {
                return localStorage.getItem('access_token');
            },
            whiteListedDomains: ['localhost']
        });

        $httpProvider.interceptors.push('jwtInterceptor');

        function checkAuthentication($transition$) {
            var $state = $transition$.router.stateService;
            var auth = $transition$.injector().get('hxAuthService');
            if (!auth.isAuthenticated()) {
                return $state.target('admin.login');
            }
        }

        function checkForScopes(scopes) {
            return function checkAuthentication($transition$) {
                var $state = $transitions.router.stateService;
                var auth = $transitions.injector().get('hxAuthService');
                if (!auth.isAuthenticated() || !auth.userHasScopes(scopes)) {
                    return $state.target('admin.login');
                }
            }
        }

    }
})();

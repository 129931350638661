/**
 * Created by dp on 7/3/17.
 */
(function () {
    "use strict";
    angular.module('hxLogInOutModule')
        .component('hxLogInOutButton', {
            templateUrl: 'src/hxLogInOut/hxLogInOutButton.html',
            controller: hxLogInOutButtonCtrl,
            bindings: {
                logInState: '@?',
                logOutState:'@?',
            }
        });
    hxLogInOutButtonCtrl.$inject = ["$scope",'hxAuthService','$state'];
    function hxLogInOutButtonCtrl($scope,hxAuthService,$state) {
        var $ctrl = this;
        $ctrl.isAuthenticated=hxAuthService.isAuthenticated();

        $ctrl.onLogIn=function(){
            if ($ctrl.logInState){
                $state.go($ctrl.logInState)
            }

        };
        $ctrl.onLogOut =function(){
            hxAuthService.logOut();
            if ($ctrl.logOutState){
                $state.go($ctrl.logOutState);
            }
        };
        var listener1=$scope.$on('HX:logInSuccess',function(){
            console.log('login success');
            $ctrl.isAuthenticated=true;


        });
        var listener2=$scope.$on('HX:logOutSuccess',function(){
            console.log('logout success');
            $ctrl.isAuthenticated=false;
        });

        $ctrl.$onDestroy=function(){
            console.log('onDestroy logInOut Component');
            listener1();
            listener2();

        }
    }

})();

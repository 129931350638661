(function(){
    "use strict";

    angular.module('hxAdminComponents')
        .component('cardList',{
            templateUrl:'src/hxAdminComponents/cardList.html',
            controller:productListController,
            bindings:{
                index:'<',
                list:'<',
                query:'<',
                tag:'<',
                onSelected:'&',
                onSelectedList:'&',
                onExists:'&',
            },
        });

    productListController.$inject=[];
    function productListController(){
      console.log("ProductList component ok!");
      var $ctrl=this;
      $ctrl.selectedlist=[];
      $ctrl.selectedobj={};

      console.log($ctrl.list);

      $ctrl.done=function(item){
        console.log("productlist post item!!");
        console.log(item);
        $ctrl.onSelected({item:item});
      };

      $ctrl.exists=function(item){
        return $ctrl.onExists({item:item});
      };
    }
})();

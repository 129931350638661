(function() {
    'use strict';

    angular.module('hxPOSAdmin')
          .controller('adminCatCtrl', adminCatCtrl);

    adminCatCtrl.$inject=['$state','AdminService','category'];
    function adminCatCtrl($state, AdminService, category){
      var $ctrl=this;
      var service=AdminService;

      //DUMMY DATA//
      // $ctrl.inObj=[
      //   {categoryName: 'Pho'},
      //   {categoryName: 'Banh My'}
      // ];
      $ctrl.inObj=category;
      console.log($ctrl.inObj);
      $ctrl.query='';

      console.log('adminCatCtrl OK!');
      $ctrl.controllerCreate=function(obj){
        console.log('Go to create category state');
        $state.go('admin.categoryAdd');
        //TODO: Call service to create new product;
      };

      $ctrl.controllerUpdate=function(id,obj){
          console.log('Update product');
          console.log('Update object ID: '+id);
          console.log($ctrl.inObj[id]);
          service.editCategory=$ctrl.inObj[id];
          $state.go('admin.categoryEdit');
        //TODO: Call service to update product;
      };

      $ctrl.controllerRemove=function(id,obj){
          console.log('Update product');
          console.log('Remove object ID: '+id);
        //TODO: Call service to update product;
      };

      $ctrl.controllerTranslate=function(id){
        service.quickTrans=$ctrl.inObj[id].categoryName;
        $state.go('admin.translationcats');
      };
    }
})();

(function () {
    "use strict";

    angular.module('hxAdminComponents')
        .component('hxProductManagement', {
            templateUrl: 'src/hxAdminComponents/hxProductManagement.html',
            controller:hxProductManagementController,
            controllerAs:'$ctrl',
            bindings:{
              inObj:'<',
              outObj:'&',
              onCreate:'&',
              onUpdate:'&'
            }
        });

    hxProductManagementController.$inject = [];
    function hxProductManagementController(){
        console.log('hxProductManager component ok !');
        var $ctrl = this;

        $ctrl.$onInit = function(){
          $ctrl.outObj={};
          if ($ctrl.inObj){
            console.log($ctrl.inObj);
            //DELETE unnecessary keys
            delete $ctrl.inObj.modifiedDate;
            // delete $ctrl.inObj.salary;

            $ctrl.outObj=$ctrl.inObj;
          } else {
            //Default values
            $ctrl.outObj.isAvailable=true;
            $ctrl.outObj.price=0;
          }
        };
        // $ctrl.outObj.hide=false;


        $ctrl.create=function(){
          //CHECK IF THERE IS DATA//
          if ($ctrl.outObj){
            console.log({outPut:$ctrl.outObj});
            $ctrl.onCreate({obj:$ctrl.outObj});
          }
        };

        $ctrl.update=function(){
          //CHECK IF THERE IS DATA//
          if ($ctrl.outObj){
            console.log({outPut:$ctrl.outObj});
            $ctrl.onUpdate({id:$ctrl.outObj.id, obj:$ctrl.outObj});
          }
        };

        $ctrl.clear=function(){
          $ctrl.outObj={};
        };

        $ctrl.remove=function(){
          if ($ctrl.outObj){
            $ctrl.outObj.isAvailable=false;
            $ctrl.onUpdate({id:$ctrl.outObj.id, obj:$ctrl.outObj});
          }
        };
    }
})();

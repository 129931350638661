(function() {
    'use strict';

    angular.module('hxPOSAdmin')
          .controller('adminTransCtrl', adminTransCtrl);

    adminTransCtrl.$inject=['$scope','$state','AdminService','listTranslations','listItems','hxIsProcessingService','$location','$anchorScroll'];
    function adminTransCtrl($scope, $state, AdminService, listTranslations, listItems, hxIsProcessingService,$location,$anchorScroll){
      var $ctrl=this;
      var service=AdminService;

      // $ctrl.inObj=service.categories;
      //DUMMY DATA//
      $ctrl.listTranslations=listTranslations.sort(function(a,b){
                                   return a.key.localeCompare(b.key);
                                  });
      $ctrl.listItems=listItems;
      $ctrl.translatedObj={};
      $ctrl.query='';
      $ctrl.selectedKey=service.quickTrans|| "";
      // $ctrl.selectedKey={};
      // $ctrl.selectedKey.key=service.quickTrans|| "";
      // console.log({selectedKey:$ctrl.selectedKey});

      console.log('adminTransCtrl OK!');

      $ctrl.translate=function(obj){
        //Check key and get index//
        var key= obj.key;
        var id;
        for (var i=0; i<listTranslations.length;i++){
          if (listTranslations[i].key==key){
            id=listTranslations[i].id;
            console.log(id);
            break;
          }
        }

        if (typeof (id)=='undefined'){
          $ctrl.create(obj);
        } else {
          $ctrl.update(id,obj);
        }
      };

      $ctrl.create=function(obj){
        console.log('Create new translation');
        $scope.$emit('is_Processing');
        service.transAPI.post(obj).then(function(response){
          $scope.$emit('done_Processing');
          $state.reload();
        });
      };

      $ctrl.update=function(id,obj){
          console.log('Update translation');
          $scope.$emit('is_Processing');
          service.transAPI.put(id,obj).then(function(response){
            $scope.$emit('done_Processing');
            $state.reload();
          });
      };

      $ctrl.transRemove=function(id){
          console.log('Update product');
          console.log('Remove object ID: '+id);
          $scope.$emit('is_Processing');
        //TODO: Call service to remove key;
          service.transAPI.delete(id).then(function(response){
          $scope.$emit('done_Processing');
          $state.reload();
        });
      };

      $ctrl.selectedKeyFunc=function(key){
         $ctrl.selectedKey=key;
         $location.hash('translation');
         $anchorScroll();
      };
    }
})();

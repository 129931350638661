(function(){
    "use strict";
    angular.module('hxPOSAdmin')
        .controller('facebookOrderController',facebookOrderController);

    facebookOrderController.$inject=['$http','hxIsProcessingService','$scope'];
    function facebookOrderController($http,hxIsProcessingService, $scope){
       console.log('facebookOrderController ok');
       var $ctrl=this;
       const appPaymentApiPath="/api/facebookOrder";

       //== DEFINE TIME ==//
       const today= Date.now();
       const one_day= 1000*60*60*24;
       $ctrl.time=[
         {
           label: "Hôm qua",
           value: new Date(today - one_day)
         },
         {
           label: "Hôm nay",
           value: new Date(today)
         },
         {
           label: "Ngày mai",
           value: new Date(today + one_day)
         },
         {
           label: "Ngày kia",
           value: new Date(today + one_day*2)
         }];

       $ctrl.currentNavItem=$ctrl.time[0].label;
       $ctrl.selectedTime=$ctrl.time[0].value;
       $ctrl.onStatistic=false;

       $ctrl.viewstatistic= function(){
         $ctrl.onStatistic=true;
       };

       $ctrl.getData=function(time){
         $ctrl.selectedTime=time;
         $ctrl.onStatistic=false;
       };

       //STATISTIC FUNCTION////
       //New displayObj//
       $ctrl.displayObjByTime={
         listProduct:[],
         totalPrice:0,
       };

       //Get statistic in new version//
       $ctrl.searchByDay=function(startDate,endDate){
         var startCreatedTime=new Date(startDate.setHours(0,0,0)).toISOString();
         var endCreatedTime=new Date(endDate.setHours(23,59,59)).toISOString();

         console.log(startCreatedTime);
         console.log(endCreatedTime);

         $scope.$emit('is_Processing');
         $http.get(appPaymentApiPath+'/statistic?startTime='+
             startCreatedTime+"&endTime="+endCreatedTime).then(function(response){
             "use strict";

             //Pass response data to list//
             console.log({responseData:response.data});
             $scope.$emit('done_Processing');
             $ctrl.displayObjByTime.startTime=startCreatedTime;
             $ctrl.displayObjByTime.endTime=endCreatedTime;

             //Change key in respone data//
             for (var i=0; i<response.data.length; i++){
                response.data[i].productName=response.data[i]['_id'];
                delete response.data[i]._id;
             }

             //Sort response data//
             response.data.sort(function(a,b){
              return a.productName.localeCompare(b.productName);
             });
             console.log(response.data);

             $ctrl.displayObjByTime.listProduct=response.data;
             $ctrl.displayObjByTime.totalPrice=0;
             for (i=0; i<$ctrl.displayObjByTime.listProduct.length; i++){
               $ctrl.displayObjByTime.totalPrice+=$ctrl.displayObjByTime.listProduct[i].revenue;
             }

             //PREPARE DATA FOR CHART//
             $ctrl.Data=$ctrl.displayObjByTime.listProduct.map(function(obj){
               return obj.revenue;
             });
             $ctrl.Labels=$ctrl.displayObjByTime.listProduct.map(function(obj){
               return obj.productName;
             });
             $ctrl.ChartOptions={responsive: true};
             console.log($ctrl.Data);

           });
           console.log({displayObj:$ctrl.displayObjByTime});
           return $ctrl.displayObjByTime;
       };
   }
})();

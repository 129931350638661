/**
 * Created by sanolab on 2017/06/11.
 */
(function () {
    "use strict";

    angular.module('groupChart',['ui.router','ngMaterial','chart.js'])
        .config(config)
        .run(firstRunFn);

    config.$inject = ['$urlRouterProvider','$locationProvider'];
    function config($urlRouterProvider,$locationProvider) {

        // If user goes to a path that doesn't exist, redirect to public root
        $urlRouterProvider.otherwise('/');
        // $locationProvider.hashPrefix('!');
        // $locationProvider.html5Mode({enabled: true,requireBase: true}); // remove hash tag in SPA, also add <base href="/"> in index.html

    }
    firstRunFn.$inject=['$window','$translate', '$rootScope','$location'];
    function firstRunFn($window,$translate, $rootScope, $location){
        var urlString='vietnamkitchenjp.com';
        var currentUrl=$window.location.href;
    }
})();

/**
 * Created by sanolab on 2017/06/11.
 */
/**
 * Created by sanolab on 2017/05/15.
 */
(function(){
    "use strict";
    angular.module('groupChart')
        .component('lineChart',{
            templateUrl:'/src/Chart/lineChart.html',
            controller:lineChartController,
            // controllerAs: "ctrl",
            bindings:{


                lineData:'<',
                // chartType:'<'
                // lineMessage:'<',
                // chartLabels:'<'
            }
        });
    lineChartController.$inject=['$scope'];
    function  lineChartController($scope) {
        var $ctrl = this;

        console.log('in side barChart');
        // $ctrl.chType=$ctrl.chartType;
        // $("canvas").addClass("chart-line");



        $ctrl.lineMessageTest=$ctrl.lineData.lineMessage;
        console.log($ctrl.lineData.message);

        $ctrl.labels=$ctrl.lineData.labels;

        $ctrl.dataTest = $ctrl.lineData.dataTest;

        console.log('data =',$ctrl.lineData.dataTest);

        $ctrl.series=$ctrl.lineData.series;
        $ctrl.colors=$ctrl.lineData.colors;

        $ctrl.options= {
            responsive: true,
            showLines: true,


            legend: {
                display: true,
                position:'top',
                reverse:false,
                fullWidth:true,



                labels: {
                    fontSize: 10,

                    fontColor: 'rgb(255, 99, 132)',
                    // boxWidth:10,
                    padding:20
                }

            },

            // pan:{
            //     enable: true,
            //     mode:'x',
            //     speed: 10,
            //     threshold: 10
            // },
            //
            // zoom: {
            //     enabled: true,
            //     mode: 'x',
            //     sensitivity: 3,
            //     limits: {
            //         max: 50,
            //         min: 0.5
            //     }
            // },

            title: {
                display: true,
                text: $ctrl.lineData.title,
                fontSize: 16


            },

            scales: {
                xAxes: [{
                    display:true,
                    scaleLabel:{
                      display:true,
                      labelString: $ctrl.lineData.xAxis.label
                    },
                    fontSize:14,
                    fontColor:'black',


                    ticks: {
                        min: $ctrl.lineData.xAxis.min,
                        max: $ctrl.lineData.xAxis.max
                    }
                }],
                yAxes: [{
                    display:true,
                    scaleLabel:{
                        display:true,
                        labelString: $ctrl.lineData.yAxis.label
                    },
                    fontSize:14,
                    fontColor:'black',

                    ticks: {
                        min: $ctrl.lineData.yAxis.min,
                        max: $ctrl.lineData.yAxis.max
                    }

                }]
            }

        };

        $ctrl.reSize= function () {
            console.log('inside restore ');
            $ctrl.resetZoom();
            $ctrl.apply();
        }



    }
})();


/**
 * Created by sanolab on 2017/06/11.
 */
/**
 * Created by sanolab on 2017/05/15.
 */
(function(){
    "use strict";
    angular.module('groupChart')
        .component('barChart',{
            templateUrl:'/src/Chart/barChart.html',
            controller:barChartController,
            // controllerAs: "ctrl",
            bindings:{


                barData:'<',

                // barMessage:'<',
                // chartLabels:'<'
            }
        });
    barChartController.$inject=['$scope'];
    function  barChartController($scope) {
        var $ctrl = this;

        console.log('in side barChart');



        $ctrl.barMessageTest=$ctrl.barData.barMessage;
        console.log($ctrl.barData.message);

        $ctrl.labels=$ctrl.barData.labels;

        $ctrl.dataTest = $ctrl.barData.dataTest;

        console.log('data =',$ctrl.barData.dataTest);

        $ctrl.series=$ctrl.barData.series;
        $ctrl.colors=$ctrl.barData.colors;

        $ctrl.options= {
            responsive: true,



            legend: {
                display: true,
                position:'top',
                reverse:false,
                fullWidth:true,



                labels: {
                    fontSize: 10,

                    fontColor: 'rgb(255, 99, 132)',
                    // boxWidth:10,
                    padding:20
                }

            },

            // pan:{
            //     enable: true,
            //     mode:'x',
            //     speed: 10,
            //     threshold: 10
            // },

            /*zoom: {
                enabled: true,
                mode: 'xy',
                sensitivity: 3,
                limits: {
                    max: 50,
                    min: 0.5
                }
            },*/

            title: {
                display: true,
                text: $ctrl.barData.title,
                fontSize: 16


            },

            scales: {
                xAxes: [{
                    display:true,
                    scaleLabel:{
                        display:true,
                        labelString: $ctrl.barData.xAxis.label
                    },
                    fontSize:14,
                    fontColor:'black',


                    ticks: {
                        min: $ctrl.barData.xAxis.min,
                        max: $ctrl.barData.xAxis.max
                    }
                }],
                yAxes: [{
                    display:true,
                    scaleLabel:{
                        display:true,
                        labelString: $ctrl.barData.yAxis.label
                    },
                    fontSize:14,
                    fontColor:'black',

                    ticks: {
                        min: $ctrl.barData.yAxis.min,
                        max: $ctrl.barData.yAxis.max
                    }

                }]
            }

        };

        $ctrl.reSize= function () {
            console.log('inside restore ');
            $ctrl.resetZoom();
            $ctrl.apply();
        }



    }
})();


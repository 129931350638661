(function(){
  "use strict";
  angular.module('hxStaffLanguageModule')
         .factory('customLoader', CustomLoader);

   CustomLoader.$inject=['$http','$q','$timeout'];
   function CustomLoader ($http, $q, $timeout) {
   // return loaderFn
   return function (options) {
       var deferred = $q.defer();
       // do something with $http, $q and key to load localization files
       // get language database
       var apiPath='/api/translation?language=';
       var translation;
       console.log(options.key);
       $http.get(apiPath+options.key)
                  .then(function(response){
                    console.log({response:response});

                    //Reform response into key:values
                    translation=response.data.reduce(function(r,o){
                       // o.key='"'+o.key+'"';
                        if (r[o.key]) {
                          r[o.key] = o.translation;
                        } else {
                            r[o.key]= o.translation;
                        }
                        return r;
                    },{});
                    console.log(translation);
                    //Append to translation array
                    Object.assign(translations[options.key],translation);
                    console.log({translation:translations[options.key]});
                   //  return translation;
                    deferred.resolve(translations[options.key]);
                  },function(error){
                    //on error
                    warning(error.statusText);
                    return error;
                  });
       return deferred.promise;
     };
   }

   //===== PREDEFINED LANGUAGE OBJECT =====//
   var translations={};
   translations['vn']={
       MEMBER_DISPLAY:'Quản lý giờ làm',
       GOTO_ADMIN:'Đến trang quản lý',
       EMAIL_MESSAGE:'Email nên có độ dài từ 10 đến 100 lý tự, mẫu: xx@xx.xx',
       PHONE_MESSAGE:'Số điện thoại nên theo mẫu ### #### ####',
       MEMBER_LIST:'Danh sách nhân viên',
       NOW_WORKING_MEMBERS:'Nhân viên đang làm việc',
       TIMECARD_WARNING:'*Timecard gần nhất của bạn có lỗi. Hãy thông báo với người quản lý !',
       STATISTICS:'Thống kê',

       //===COMMON ==//
       SELECT_STATISTIC_BY_DAY:'Chọn ngày',
       STATISTIC_NOTE:'*Ghi chú: thống kế tính từ 0h của ngày bắt đầu đến 24h của ngày kết thúc',
       START_DATE:'Ngày bắt đầu',
       END_DATE:'Ngày kết thúc',
       STATISTIC_INFO:'Thông tin thống kê',
       SEARCH:'Tìm kiếm',
       SOLD:'Đã bán',
       TOTAL_ORDERS_PRICE:'Tổng giá trị đơn hàng',
       TOTAL_ORDERS_PRICE_BY_PAYMENT:"Tổng giá trị đơn hàng theo hình thức thanh toán",
       CREDIT: "Thẻ Credit",
       CASH : "Tiền mặt",
       TRANSLATION_LANG:'Ngôn ngữ',
       FROM: 'Chuyển từ',
       TO:'Thành từ',
       TRANSLATION:'DỊCH NGHĨA',
       TRANSLATE:'Dịch',
       ENTER_KEYWORD:'Điền từ khoá',
       "CATEGORY MANAGEMENT":'Quản lý nhóm sản phẩm',
       "PRODUCT MANAGEMENT":'Quản lý sản phẩm',

       //====IMPORTED FROM JSON====//
       "ENTER_YOUR_ID": "Điền số ID",
       "ENTER_YOUR_PASSWORD": "Điền mật khẩu",
       "LOGIN":"Đăng nhập",
       "START_TIME":"Thời gian bắt đầu",
       "STATUS":"Trạng thái",
       "NAME":"Tên",
       "THIS_IS_REQUIRED":"Hãy điền vào đây",
       "BASIC_INFOS":"Thông tin cơ bản",
       "STAFF_ID":"ID nhân viên",
       "GENDER":"Giới tính",
       "MALE":"Nam",
       "FEMALE":"Nữ",
       "OTHERS":"Khác",
       "ROLE":"Vai trò",
       "MANAGER":"Quản lý",
       "FULL_TIME":"Toàn thời gian",
       "PART_TIME":"Bán thời gian",
       "EMAIL":"Email",
       "PHONE_NUMBER":"Số điện thoại",
       "SALARY":"Lương",
       "UNIT":"Đơn vị",
       "BASIC":"Cơ bản",
       "HOLIDAY_SCALE":"Trong ngày nghỉ",
       "NIGHT_SHIFT_SCALE":"Trong ca đêm",
       "BREAK_SCALE":"Trong giờ nghỉ",
       "CREATE":"Tạo mới",
       "CLEAR":"Huỷ",
       "REMOVE":"Xoá",
       "CREATED_TIME":"Khởi tạo ngày",
       "LAST_MODIFIED_TIME":"Chỉnh sửa lần cuối",
       "STATISTIC":"Thống kê",
       "ADD NEW":"Tạo mới",
       "AVAILABLE CATEGORIES":"NHÓM SẢN PHẨM HIỆN CÓ",
       "AVAILABLE PRODUCTS":"SẢN PHẨM HIỆN CÓ",
       "PRODUCT_EDIT":"SỬA SẢN PHẨM",
       "CATEGORY_EDIT":"SỬA NHÓM",
       "PRODUCT_CREATE":"TẠO SẢN PHẨM",
       "CATEGORY_CREATE":"TẠO NHÓM",
       "PRODUCTS":"Sản phẩm",
       "CHOOSE PRODUCTS":"Chọn sản phẩm",
       "UPDATE":"CẬP NHẬT",
       "IS_AVAILABLE":"Đang sử dụng",
       "* UNCHECK TO DELETE":"* Bỏ chọn để xoá",
       "THIS PRODUCT":"sản phẩm này",
       "THIS CATEGORY":"nhóm sản phẩm này",
       "PRICE":"Giá",
       "KITCHEN_STATION":"VỊ TRÍ HIỂN THỊ",
       "KITCHEN":"Trong Bếp",
       "PAN":"Quầy bánh mỳ",
       "DETAILS":"THÔNG TIN CHI TIẾT",
       "TODAY":"NGÀY HIỆN TẠI",
       "CHART_REVENUE":"BIỂU ĐỒ",
       "BY_PRODUCTS":"THEO SẢN PHẨM",
       "BY_DAYS":"THEO NGÀY",
       "CATEGORY":"NHÓM SẢN PHẨM",
       "PRODUCT":"SẢN PHẨM",
       "MANAGEMENT":"QUẢN LÝ",
       "VIEW":"XEM",
       "QUANTITY":"Số lượng",
       "PROCEDURE":"Thao tác",
       "PLACE_AND_TIME":"Địa điểm & Thời gian",
       "PLACE":"Địa điểm",
       "TOTAL_PRICE":"Tổng tiền",
       DELETE_WARNING:'Đối tượng sau khi xoá sẽ không thể khôi phục lại (trừ phi tạo mới)',
       DELETE_WARNING_TITLE:'Bạn có chắc không ?',
       "DELETE_ITEMS":"Đã xóa",
       "DELETE_ITEMS_WARN":"Không xuất hiện trong biểu đồ",
       "DELETE ORDER": "Xóa đơn hàng",
       "INVENTORY PRODUCTS": "SẢN PHẨM TRONG KHO",
       "INVENTORY CATEGORIES": "NHÓM SẢN PHẨM TRONG KHO",
       "INVENTORY": "KHO HÀNG",
       "EDIT":"Thay đổi",
       "NEW QUANTITY": "Số lượng mới",
       "UPDATE ALL": "Cập nhật tất cả"
   };


   translations['jp']={
       //====PAYMENT APP=====//
       PAYMENT:'会計',
       PRINTER_STATUS:'プリンター管理',
       DISCOUNT:'値引き',
       CUSTOMER_PAY:'預かり金額',
       CASH_BACK:'お釣り',
       GENDER:'性別',
       PAYMENT_DETAILS: 'オーダーの詳細',
       SEARCH_ORDERS:'オーダー検索',
       SEARCH_SEAT_NUMBER:'席検索',
       TAKE_OUT:'TakeOut',
       ERRORS:'オーダー削除',
       STATISTICS:'統計',
       SELECT_STATISTIC_BY_DAY:'日付選択',
       STATISTIC_NOTE:'*注意: 統計機能は開始日の0hより終了日の24hまで計算する',
       START_DATE:'開始日',
       END_DATE:'終了日',
       STATISTIC_INFO:'統計情報',
       SEARCH:'検索',

       CUSTOMER_VIEW:'会計確認',
       SEAT:'席',
       SEAT_NUMBER:'席番号',
       MAN:'男',
       WOMAN:'女',
       PAY:'会計',
       REASON:'理由',
       DELETE_ORDERS:'オーダー削除',
       ORDER_ID:'オーダー番号',
       TOTAL_ORDERS_PRICE:'オーダー合計',
       TOTAL_ORDERS_PRICE_BY_PAYMENT:"支払い方法によりオーダー合計",
       CREDIT: "クレジットカード",
       CASH : "現金",
       TRANSLATION_LANG:'言語',
       FROM: 'から',
       TO:'まで',
       TRANSLATION:'翻訳',
       TRANSLATE:'翻訳',
       ENTER_KEYWORD:'キーワード記入',
       "CATEGORY MANAGEMENT":'カテゴリー管理',
       "PRODUCT MANAGEMENT":'商品管理',

       //===COMMON ==//
       ORDER:'オーダー',
       OR:'もしくは',
       ALL:'全部',
       MENUS:'メニュー',
       PRODUCT_NAME:'商品名',
       ORDERED_ITEMS:'注文済商品',
       TOTAL_PRICE:'合計',
       CONFIRM:'確認',
       TAGS:'タグ',
       TODAY:'今日',
       SOLD:'売上',
       TOTAL:'合計',
       QUANTITY:'数量',

       //= DELETE ORDER REASON =//
       DELETE_REASON_WRONG_ORDER:'オーダー情報が違う',
       DELETE_REASON_WRONG_PRODUCT:'商品情報が違う',
       DELETE_REASON_OTHERS:'その他',

       //=For category Display
       'CHƯA CÓ SỐ GHẾ!':'シートが選択されていません',

      //====IMPORTED FROM JSON====//
      "ENTER_YOUR_ID": "ID",
      "ENTER_YOUR_PASSWORD": "パスワード",
      "LOGIN":"ログイン",
      "START_TIME":"Thời gian bắt đầu",
      "STATUS":"状態",
      "NAME":"名前",
      "THIS_IS_REQUIRED":"Hãy điền vào đây",
      "BASIC_INFOS":"Thông tin cơ bản",
      "STAFF_ID":"ID nhân viên",
      "MALE":"Nam",
      "FEMALE":"Nữ",
      "OTHERS":"Khác",
      "ROLE":"Vai trò",
      "MANAGER":"Quản lý",
      "FULL_TIME":"Toàn thời gian",
      "PART_TIME":"Bán thời gian",
      "EMAIL":"Email",
      "PHONE_NUMBER":"Số điện thoại",
      "SALARY":"Lương",
      "UNIT":"Đơn vị",
      "BASIC":"Cơ bản",
      "HOLIDAY_SCALE":"Trong ngày nghỉ",
      "NIGHT_SHIFT_SCALE":"Trong ca đêm",
      "BREAK_SCALE":"Trong giờ nghỉ",
      "CREATE":"作成",
      "CLEAR":"消す",
      "UPDATE":"更新",
      "REMOVE":"Xoá",
      "CREATED_TIME":"Khởi tạo ngày",
      "LAST_MODIFIED_TIME":"Chỉnh sửa lần cuối",
      "STATISTIC":"統計",
      "ADD NEW":"新規",
      "AVAILABLE CATEGORIES":"現在のカテゴリー",
      "AVAILABLE PRODUCTS":"現在の商品",
      "PRODUCT_EDIT":"商品更新",
      "CATEGORY_EDIT":"カテゴリー更新",
      "PRODUCT_CREATE":"商品作成",
      "CATEGORY_CREATE":"カテゴリー作成",
      "PRODUCTS":"商品",
      "CHOOSE PRODUCTS":"商品選択",
      "IS_AVAILABLE":"利用可能",
      "* UNCHECK TO DELETE":"* チェックしないとは削除する",
      "THIS PRODUCT":"",
      "THIS CATEGORY":"",
      "PRICE":"値段",
      "KITCHEN_STATION":"表示場所",
      "KITCHEN":"キッチン",
      "PAN":"バンミー",
      "DETAILS":"詳細情報",
      "CHART_REVENUE":"図表化",
      "BY_PRODUCTS":"[製品に基づいた]",
      "BY_DAYS":"[日付に基づいた]",
      "CATEGORY":"カテゴリー",
      "PRODUCT":"製品",
      "MANAGEMENT":"管理",
      "VIEW":"見る",
      DELETE_WARNING:'削除したものは後で復活できません',
      DELETE_WARNING_TITLE:'ご確認してください',
      "DELETE_ITEMS":"隠したアイテム",
      "DELETE_ITEMS_WARN":"図表で見られない",
      "DELETE ORDER": "伝票削除",
      "INVENTORY PRODUCTS": "在庫品",
      "INVENTORY CATEGORIES": "在庫カテゴリー",
      "INVENTORY": "在庫"
   };
})();

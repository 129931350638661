(function(){
    "use strict";
    angular.module('hxPOSAdmin')
        .controller('delOrderController',delOrderController);

    delOrderController.$inject=['$http','hxIsProcessingService','$scope', '$state'];
    function delOrderController($http,hxIsProcessingService, $scope, $state){
       console.log('delOrderController ok');
       var $ctrl=this;
       
       const appPaymentApiPath="/api";
       const today= Date.now();
       
       $ctrl.$onInit=function(){
        $ctrl.today= new Date(new Date().toDateString());
        console.log($ctrl.today);
        //DEFAULT DATE//
        $ctrl.startDate=new Date(Date.now());
        $ctrl.endDate=new Date(Date.now());//Today//
      };

       $ctrl.selectedDeleteObj = [];
       
       $ctrl.search=function(){
        var startCreatedTime=new Date($ctrl.startDate.setHours(0,0,0)).toISOString();
        var endCreatedTime=new Date($ctrl.endDate.setHours(23,59,59)).toISOString();

        $http.get(appPaymentApiPath+'/combinedOrder/?status=1&startCreatedTime='+startCreatedTime+'&endCreatedTime='+
        endCreatedTime).then(function(response){
            console.log({response:response.data});
            $ctrl.list=response.data.map(function(obj){
              var newObj={};
              newObj=obj;
              if (!newObj.totalPrice){
                newObj.totalPrice=0;
                newObj.listProduct.forEach(function(product){
                  newObj.totalPrice+=product.price*product.quantity;
                });
              }
              return newObj;
            });
        });
      };

      $ctrl.selected=function(item){
        if ($ctrl.exists(item)){
          var idx = $ctrl.selectedDeleteObj.indexOf(item);
          $ctrl.selectedDeleteObj.splice(idx);
        } else{
          $ctrl.selectedDeleteObj.push(item);
        }
      };

      $ctrl.exists=function(item){
        if ($ctrl.selectedDeleteObj){
          return $ctrl.selectedDeleteObj.some(function(el){
            return el._id === item._id;
           });
        }
      };

      $ctrl.delete=function(){
        console.log($ctrl.selectedDeleteObj);
        var param=angular.toJson({
          status:5
        });
        var count = $ctrl.selectedDeleteObj.length;
        $scope.$emit('is_Processing');
        for(var i = 0; i < count; i++) {          
          hideItem(i, param);
        }
        $scope.$emit('done_Processing');
        $state.reload();
     };

     var hideItem=function(i, param){
      var id=$ctrl.selectedDeleteObj[i]._id;
          console.log(id);

          //Delete data from server by API//
          $http.post(appPaymentApiPath+'/combinedOrder/'+id+'/edit',param).then(function(response){
                  console.log({response:response});
          });
     };
  }
})();

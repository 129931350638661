(function() {
    'use strict';

    angular.module('hxPOSAdmin')
          .controller('adminCatInvEditCtrl', adminCatInvEditCtrl);

    adminCatInvEditCtrl.$inject=['$state','AdminService','$scope','hxIsProcessingService','$mdDialog'];
    function adminCatInvEditCtrl($state, AdminService, $scope, hxIsProcessingService, $mdDialog){
      var $ctrl=this;
      var service=AdminService;

      //DUMMY DATA//
      // $ctrl.inObj=[
      //   {categoryName: 'Pho'},
      //   {categoryName: 'Banh My'}
      // ];

      console.log('adminCatInvEditCtrl OK!');
      $ctrl.catEditCreate=function(obj){
        console.log('Create new category');
        $scope.$emit('is_Processing');
        service.catsInvAPI.post(obj).then(function(response){
          $scope.$emit('done_Processing');
          $state.go('admin.inventory');
        });
      };

      $ctrl.catEditUpdate=function(id,obj){
          console.log('Update category');
          $scope.$emit('is_Processing');
          service.catsInvAPI.put(id,obj).then(function(response){
            $scope.$emit('done_Processing');
            $state.go('admin.inventory');
          });
      };

      $ctrl.catEditRemove=function(id){
          console.log('Update product');
          console.log('Remove object ID: '+id);
          //Show popup for confirmation
          var confirm= $mdDialog.confirm()
              .title('Are you sure ?')
              .textContent('Object will be deleted permanently and cannot be restored')
              .ariaLabel('Delete')
              .ok('OK')
              .cancel('CANCEL');
          $mdDialog.show(confirm).then(function() {
              $scope.$emit('is_Processing');
              service.catsInvAPI.delete(id).then(function(response){
              $scope.$emit('done_Processing');
              $state.go('admin.inventory');
            }, function() {
              console.log('Cancelled');
            });
        });
      };
    }
})();

(function () {
    "use strict";

    angular.module('hxAdminComponents')
        .component('hxCatManagement', {
            templateUrl: 'src/hxAdminComponents/hxCatManagement.html',
            controller:hxCatManagementController,
            controllerAs:'$ctrl',
            bindings:{
              inObj:'<',
              listProducts:'<',
              outObj:'&',
              onCreate:'&',
              onUpdate:'&',
              onRemove:'&'
            }
        });

    hxCatManagementController.$inject = ['AdminService'];
    function hxCatManagementController(AdminService){
        console.log('hxCategoryManager component ok !');
        var $ctrl = this;
        var service =AdminService;

        $ctrl.$onInit=function(){
          $ctrl.listProducts=$ctrl.listProducts.filter(function(oldObj){
              return (oldObj.isAvailable===true);
            });
          $ctrl.outObj={};

          if ($ctrl.inObj){
            delete $ctrl.inObj.modifiedData;
            $ctrl.inObj.items=$ctrl.inObj.items.filter(function(x){
              return (x.isAvailable===true);
            });
            $ctrl.outObj=$ctrl.inObj;
          } else {
            //Default values
            $ctrl.outObj.isAvailable=true;
          }

        };

        $ctrl.create=function(){
          //CHECK IF THERE IS DATA//
          console.log('Begin create conllection');
          if ($ctrl.outObj){
            console.log({outPut:$ctrl.outObj});
            $ctrl.onCreate({obj:$ctrl.outObj});
          }
        };

        $ctrl.update=function(){
          //CHECK IF THERE IS DATA//
          if ($ctrl.outObj){
            console.log({outPut:$ctrl.outObj});
            $ctrl.onUpdate({id:$ctrl.outObj.id, obj:$ctrl.outObj});
          }
        };

        $ctrl.remove=function(id){
          console.log(id);
          $ctrl.onRemove({id:id});
        };

        $ctrl.clear=function(){
          $ctrl.outObj={};
        };

    }
})();

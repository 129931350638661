(function() {
    'use strict';

    angular.module('hxPOSAdmin')
          .controller('adminProductCtrl', adminProductCtrl);

    adminProductCtrl.$inject=['$state','AdminService','products'];
    function adminProductCtrl($state, AdminService, products){
      var $ctrl=this;
      var service=AdminService;

      $ctrl.inProducts=products;
      $ctrl.query='';

      console.log('adminProductCtrl OK!');
      $ctrl.controllerCreate=function(){
        console.log('Create new product');
        $state.go('admin.productAdd');
        //TODO: Call service to create new product;
      };

      $ctrl.controllerUpdate=function(id){
          console.log('Update product');
          console.log('Update object ID: '+id);
          console.log($ctrl.inProducts[id]);
          service.editProduct=$ctrl.inProducts[id];
          $state.go('admin.productEdit');
      };

      $ctrl.controllerTranslate=function(id){
        service.quickTrans=$ctrl.inProducts[id].productName;
        $state.go('admin.translation');
      };
    }
})();

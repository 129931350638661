(function () {
    "use strict";

    angular.module('hxAdminComponents')
        .component('hxSideBar', {
            templateUrl: 'src/hxAdminComponents/hxSideBar.html',
            controller:hxSideBarController,
            controllerAs:'$ctrl',
            // bindings:{
            //   user:'<',
            //   listStates:'<',
            //   listLocation:'<',
            //   isLock:'<',
            //   onLogOut:'&',
            //   onSelectLocation:'&'
            // }
        });

    hxSideBarController.$inject = ['$state', '$mdSidenav'];
    function hxSideBarController($state, $mdSidenav){
        console.log('hxSideBar component ok !');
        var $ctrl = this;

        //DUMMY DATA//
        $ctrl.user={
          name: "TEST",
          position: "MANAGER"
        };

        $ctrl.listStates=[
          {
            state: "admin.topPage",
          },
          {
            state:"admin.category",
            substate:[
              {state: "admin.categoryAdd"},
            ]
          },
          {
            state:"admin.product",
            substate:[
              {state: "admin.productAdd"},
            ]
          },
          {
            state:"admin.statistic"
          }
        ];

        $ctrl.listLocation=[
          {name: "Osu"},
          {name: "Yagoto"}
        ];

        $ctrl.listLanguage=['vn','jp'];

        $ctrl.isLock="true";
        // END DUMMY DATA//

        $ctrl.toggleLeft = buildToggler('left');
        $ctrl.toggleRight = buildToggler('right');
        console.log($ctrl.obj);

        function buildToggler(componentId) {
          return function() {
            $mdSidenav(componentId).toggle();
          };
        }

        $ctrl.logout=function(){
          $ctrl.onLogOut();
        };

        $ctrl.goToState=function(state){
          $state.go(state);
          $ctrl.toggleLeft();
        };
    }
})();

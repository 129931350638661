(function () {
    "use strict";

    angular.module('hxLogInOutModule')
        .service('hxAuthService', hxAuthService);
    /**
     * @description dependency list ['$rootScope','angularAuth0','$timeout']
     * @type {Array}
     */
    hxAuthService.$inject = ['$rootScope','angularAuth0','$timeout'];
    /**
     * @memberof hxAdminComponents
     * @ngdoc service
     * @name hxAuthService
     * @description manage login logout states; emit event for successful login or logout
     * Sucess event (HX:logInSuccess) or (HX:logOutSuccess) (HX:logInOutError).Note: service only create the first time
     * @param $rootScope {Service}
     * @param angularAuth0 {Service}
     * @param $timeout {Service}
     */
    function hxAuthService($rootScope,angularAuth0,$timeout) {
        var service = this;
        var logInSuccessEvent='HX:logInSuccess';
        var logOutSuccessEvent="HX:logOutSuccess";
        var errorEvent= "HX:logInOutError";

        var userProfile;

        function errorHandler(err){
            console.log(err);
            $rootScope.$broadcast(errorEvent,{message:err});
        }
        /**
         * @memberof hxAuthService
         * @description wrapper for sending login and password to angularAuth0
         * @param username {String}
         * @param password {String}
         */
        service.logIn =function(username, password) {
            angularAuth0.client.login({
                realm: 'Username-Password-Authentication',
                username: username,
                password: password,
            }, function(err, authResult) {
                if (err) {
                    errorHandler(err);
                    return;
                }
                if (authResult && authResult.idToken) {
                    setSession(authResult);
                    console.log(authResult);
                    //very likely this don't aware in angular context, since it work on the promise
                    $rootScope.$broadcast(logInSuccessEvent,{message:'successful login'});
                }
            });
        };
        /**
         * @memberof hxAuthService
         * @description for sign up.Note not well tested
         * @param username
         * @param password
         */
        service.signUp=function (username, password) {
            angularAuth0.redirect.signupAndLogin({
                connection: 'Username-Password-Authentication',
                email: username,
                password: password
            }, function(err) {
                if (err) {
                    errorHandler(err);
                    return;
                }
            });
        }

        /**
         * @memberof hxAuthService
         * @description handleAuthentication for callback, using login form form auth0.Note it is not used yet
         *
         *
         */
        service.handleAuthentication=function () {
            angularAuth0.parseHash(function(err, authResult) {
                if (authResult && authResult.idToken) {
                    setSession(authResult);
                    $rootScope.$broadcast(logInSuccessEvent,{message:'successful login'});
                } else if (err) {
                    $timeout(function() {
                        errorHandler(err)
                    });
                    console.log(err);
                }
            });
        };
        /**
         * @memberof hxAuthService
         * @description set up localStorage for receive token
         * @param authResult {Object} the result of the authentication process
         */
        function setSession(authResult) {
            // Set the time that the access token will expire at
            var expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

            var scopes = authResult.scope || '';

            localStorage.setItem('access_token', authResult.accessToken);
            localStorage.setItem('id_token', authResult.idToken);
            localStorage.setItem('expires_at', expiresAt);
            localStorage.setItem('scopes', scopes);
        }

        /**
         * @memberof hxAuthService
         * @description handle logout process and emit (HX:logOutSuccess) event when done
         */
        service.logOut=function () {
            // Remove tokens and expiry time from localStorage
            localStorage.removeItem('access_token');
            localStorage.removeItem('id_token');
            localStorage.removeItem('expires_at');
            localStorage.removeItem('scopes');
            console.log("log out service");
            $rootScope.$broadcast(logOutSuccessEvent,{message:'successful logOut'});
        };

        service.isAuthenticated=function () {
            // Check whether the current time is past the
            // access token's expiry time
            var expiresAt = JSON.parse(localStorage.getItem('expires_at'));
            return new Date().getTime() < expiresAt;
        }
        /**
         * @memberof hxAuthService
         * @description check if user have scope;
         * @param scopes {Object}
         * @return {boolean} true when user has all scopes need to access the resource
         */
        service.userHasScopes=function (scopes) {
            var grantedScopes = JSON.parse(localStorage.getItem('scopes')).split(' ');
            for (var i = 0; i < scopes.length; i++) {
                if (grantedScopes.indexOf(scopes[i]) < 0) {
                    return false;
                }
            }
            return true;
        }
        /**
         * @memberof hxAuthService
         * @param cb {function} a callback function when done
         */
        service.getProfile =function (cb) {
            var accessToken = localStorage.getItem('access_token');
            if (!accessToken) {
                throw new Error('Access token must exist to fetch profile');
            }
            angularAuth0.client.userInfo(accessToken, function(err, profile) {
                if (profile) {
                    setUserProfile(profile);
                }
                cb(err, profile);
            });
        };

        function setUserProfile(profile) {
            userProfile = profile;
        }

        service.getCachedProfile=function() {
            return userProfile;
        };
    }

})();
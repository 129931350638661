(function () {
    "use strict";

    angular.module('hxStaffLanguageModule')
        .component('hxMakeTranslate', {
            templateUrl: 'src/hxStaffLanguage/hxMakeTranslate.html',
            controller:hxMakeTranslateController,
            controllerAs:'$ctrl',
            bindings:{
              listItem:'<',
              inObj:'<',
              outObj:'=',
              onTranslate:'&'
            }
        });

    hxMakeTranslateController.$inject = ['$element'];
    function hxMakeTranslateController($element){
        console.log('hxMakeTranslateController component ok !');
        var $ctrl = this;

        $ctrl.$onInit=function(){

          $ctrl.outObj={};
          //default language//
          $ctrl.outObj.language="jp";
          $ctrl.searchTerm=$ctrl.inObj||"";
          console.log($ctrl.searchTerm);
        };

        $ctrl.$onChanges=function(changes){
          console.log({changes:changes});
          $ctrl.searchTerm=changes.inObj.currentValue;
        };

        $ctrl.translate=function(){
          //CHECK IF THERE IS DATA//
          console.log('Begin translation');
          if ($ctrl.selectedObj){
            $ctrl.outObj.key=$ctrl.selectedObj.productName || $ctrl.selectedObj.categoryName;
            console.log({outPut:$ctrl.outObj});
            $ctrl.onTranslate({obj:$ctrl.outObj});
          } else {
            $ctrl.outObj.key=$ctrl.searchTerm;
            console.log({outPut:$ctrl.outObj});
            $ctrl.onTranslate({obj:$ctrl.outObj});
          }
        };

        $ctrl.clearSearchTerm = function() {
          $ctrl.searchTerm = '';
        };

        $element.find('input').on('keydown', function(ev) {
          ev.stopPropagation();
        });
    }
})();

/**
 * Created by dp on 6/7/17.
 */
(function(){
    "use strict";

    angular.module('hxAdminComponents')
        .component('inventoryStatistics',{
            templateUrl:'src/hxAdminComponents/inventoryStatistic.html',
            controller:inventoryStatisticCtrl,
            bindings:{
                displayObj:'<',
                displayObj2:'<',
                onSearch:'&',
                onPrint:'&'
            }
        });

    inventoryStatisticCtrl.$inject=[];
    function inventoryStatisticCtrl(){
        console.log("statisticDisplay component ok!");
        var $ctrl=this;

        $ctrl.$onInit=function(){
            $ctrl.today= new Date(new Date().toDateString());
            console.log($ctrl.today);
            //DEFAULT DATE//
            $ctrl.startDate=new Date(Date.now());
            $ctrl.endDate=new Date(Date.now());//Today//
        };

        console.log($ctrl.displayObj);

        $ctrl.search=function(){
            $ctrl.onSearch({startDate:$ctrl.startDate, endDate:$ctrl.endDate});
        };

        $ctrl.print=function(){
            $ctrl.onPrint();
        };
    }
})();

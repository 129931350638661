/**
 * Created by sanolab on 2017/06/14.
 */

(function() {
    "use strict";
        angular.module('groupChart')
            .controller('chartDisplayController', chartDisplayController);
            chartDisplayController.$inject = ['$rootScope','$timeout'];
            function chartDisplayController($rootScope,$timeout) {
            var $ctrlChart = this;



            console.log('chartDisplay');
            $ctrlChart.chartLineType="chart-line";
            $ctrlChart.chartbarType="chart-bar";

            $ctrlChart.listChart=[
                "lineChart",
                "barChart",
                "doughnutChart"
            ];



                //randomdata
                $ctrlChart.randomScalingFactor = function() {
                    return Math.round(Math.random() * 100);
                };

                $ctrlChart.randomColor=function () {
                    return Math.floor(Math.random()*16777215).toString(16);

                };

                $ctrlChart.randomColor1=function(opacity) {
                    return (
                        "rgba(" +
                        $ctrlChart.randomScalingFactor() +
                        "," +
                        $ctrlChart.randomScalingFactor() +
                        "," +
                        $ctrlChart.randomScalingFactor() +
                        "," +
                        (opacity || ".3") +
                        ")"
                    );
                };
                /// random dataset
                $ctrlChart.i=0;
                $ctrlChart.dataRandom=[100, 59, 80, 81, 56, 55, 40];
                $ctrlChart.loadData=function () {

                        $ctrlChart.dataRandom=[ $ctrlChart.randomScalingFactor(),
                            $ctrlChart.randomScalingFactor(),
                            $ctrlChart.randomScalingFactor(),
                            $ctrlChart.randomScalingFactor(),
                            $ctrlChart.randomScalingFactor(),
                            $ctrlChart.randomScalingFactor(),
                            $ctrlChart.randomScalingFactor()];


                   // $ctrlChart.dataRandom=dataRandom;
                     $ctrlChart.i++;
                     $timeout($ctrlChart.loadData,500);
                     console.log('i=',$ctrlChart.i);
                     return $ctrlChart.i;

                };
                //

                // $ctrlChart.time = Date.now();
                $ctrlChart.checkData=$ctrlChart.loadData();
                console.log($ctrlChart.checkData);



                ///------------------------------------------



                //data initial
                $ctrlChart.labelArray=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                $ctrlChart.backgroundColorArray= ['#ff6384','#36a2eb','#cc65fe','#ffce56','#37a1eb','#ff6254','#cc55fe','#fe2284','#55a2eb','#dd65fe','#34ce56'];
                //--------------------





                $ctrlChart.chartConfig= {
                    labelsConfig: {
                        labelsData:["January", "February", "March", "April", "May", "June", "July"], // y-axis value
                        chartTitle: "THONG KE DOANH THU",  // title of Chart
                        xAxis: {
                            label:"Months",          // the x-label
                             // min:"January",       // min x-axis value
                             // max:"April"          // max x-axis value
                        },
                        yAxis: {
                            label:"Income",          // the y-label
                             // min:0,               // min y-axis value
                             // max:120              // max y-axis value
                        }
                    },
                    dataSet: [
                        {
                            series: "pho",                                 // name of series data
                            data: [100, 59, 80, 81, 56, 55, 40],           // data or x-axis value
                            // data: $ctrlChart.dataRandom,
                            color: {                                       // color config
                                backgroundColor: 'blue',
                                borderColor: 'blue',
                                fill: false
                            }


                        },

                        {
                            series : "banh my",
                            data :[28, 48, 40, 19, 20, 27, 90],
                            color: {
                                backgroundColor: 'red',
                                borderColor: 'red',
                                fill:false
                            }
                        },

                        {
                            series : "thit cho",
                            data :[15, 60, 20, 50, 16, 22, 70],
                            color: {
                                backgroundColor: 'green',
                                borderColor: 'green',
                                fill:false
                            }
                        }
                    ],

                    // doughnut color config
                    colorDoughnut: {
                        backgroundColor: ['#ff6384','#36a2eb','#cc65fe','#ffce56','#37a1eb','#ff6254','#cc55fe'],
                        borderColor: 'white',
                        fill: false
                    },

                    // note of chart
                    lineMessageTest: "This is line graph",
                    barMessageTest : "This is bar graph",
                    doughnutMessageTest : "This is doughnut graph"


            };




                $ctrlChart.data = {
                    dataTest:[$ctrlChart.chartConfig.dataSet[0].data],
                    labels:$ctrlChart.chartConfig.labelsConfig.labelsData,
                    series:[$ctrlChart.chartConfig.dataSet[0].series],
                    colors:[$ctrlChart.chartConfig.dataSet[0].color],
                    colorDoughnut:[$ctrlChart.chartConfig.colorDoughnut],
                    title:$ctrlChart.chartConfig.labelsConfig.chartTitle,
                    xAxis:$ctrlChart.chartConfig.labelsConfig.xAxis,
                    yAxis:$ctrlChart.chartConfig.labelsConfig.yAxis,
                    lineMessage:$ctrlChart.chartConfig.lineMessageTest,
                    barMessage:$ctrlChart.chartConfig.barMessageTest,
                    doughnutMessage:$ctrlChart.chartConfig.doughnutMessageTest


                };

                $ctrlChart.lenghtDataArrayTemp=$ctrlChart.data.dataTest.length;
                ////////
                // $ctrlChart.lenghtDataArrayTemp1=$ctrlChart.data1.dataTest.length;
                ///////
                console.log('length before =',$ctrlChart.lenghtDataArrayTemp);

                $ctrlChart.maxGroupData=3;


                    $ctrlChart.addDataSet = function () {

                            //doughnut
                        /*$ctrlChart.data1.dataTest.push($ctrlChart.dataSet1[$ctrlChart.lenghtDataArrayTemp].data);
                        $ctrlChart.data1.series.push($ctrlChart.dataSet1[$ctrlChart.lenghtDataArrayTemp].series);
                        $ctrlChart.data1.colors.push($ctrlChart.dataSet1[$ctrlChart.lenghtDataArrayTemp].color);
                        $ctrlChart.lenghtDataArrayTemp1++;*/
                            //

                            $ctrlChart.data.dataTest.push($ctrlChart.chartConfig.dataSet[$ctrlChart.lenghtDataArrayTemp].data);
                            $ctrlChart.data.series.push($ctrlChart.chartConfig.dataSet[$ctrlChart.lenghtDataArrayTemp].series);
                            $ctrlChart.data.colors.push($ctrlChart.chartConfig.dataSet[$ctrlChart.lenghtDataArrayTemp].color);
                            $ctrlChart.data.colorDoughnut.push($ctrlChart.chartConfig.colorDoughnut);
                            $ctrlChart.lenghtDataArrayTemp++;

                            // console.log('length',$ctrlChart.lenghtDataArrayTemp)


                    };

                    $ctrlChart.addData = function () {

                        var month = $ctrlChart.labelArray[$ctrlChart.chartConfig.labelsConfig.labelsData.length % $ctrlChart.labelArray.length];
                        var newColor= $ctrlChart.backgroundColorArray[$ctrlChart.chartConfig.labelsConfig.labelsData.length % $ctrlChart.backgroundColorArray.length];
                        $ctrlChart.chartConfig.labelsConfig.labelsData.push(month);
                        // $ctrlChart.chartConfig.colorDoughnut.backgroundColor.push($ctrlChart.randomColor());
                        $ctrlChart.chartConfig.colorDoughnut.backgroundColor.push(newColor);
                        console.log('color',$ctrlChart.chartConfig.colorDoughnut.backgroundColor);
                        $ctrlChart.chartConfig.dataSet.forEach(function (dataSetItem) {
                            dataSetItem.data.push($ctrlChart.randomScalingFactor())
                        });
                        // console.log('length',$ctrlChart.randomScalingFactor())
                    };




            }
    }
)();

/**
 * Login
 */
(function(){
    "use strict";
    angular.module('hxLogInOutModule',['auth0.auth0'])
        .config(hxLoginConfig)
    ;

    hxLoginConfig.$inject = ['angularAuth0Provider'];

    function hxLoginConfig(angularAuth0Provider) {

        console.log('hxLogInOutModule configured!')
        // Initialization for the angular-auth0 library
        angularAuth0Provider.init({
            clientID: AUTH0_CLIENT_ID,
            domain: AUTH0_DOMAIN,
            responseType: 'token id_token',
            audience: AUTH0_AUDIENCE,
            redirectUri: AUTH0_CALLBACK_URL,
            scope: REQUESTED_SCOPES
        });
    }
})();
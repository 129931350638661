(function(){
    angular.module('hxPOSAdmin')
        .service('AdminService',AdminService)
        .constant('apiPath','/api');
   AdminService.$inject=['$http','$rootScope','apiPath','hxNotificationService'];
    function AdminService($http,$rootScope,apiPath,hxNotificationService){
        var service = this;

        //DUMMY DATA
        service.editObj={
          name: "Test"
        };

        service.editCategory={};
        service.editProduct={};
        service.quickTrans="";
        service.editInvCategory={};
        service.editInvProduct={};

        //=== PRODUCT API ===//
        var productPath='/api/product';
        service.productAPI= new restAPI(productPath,service.products);
        //=== END PRODUCT API ===//

        //=== PRODUCT INVENTORY API ===//
        var productInvPath='/api/productInventory';
        service.productInvAPI= new restAPI(productInvPath,service.productsInv);
        //=== END PRODUCT API ===//

        //===== CATEGORY API =====//
        var catsPath='/api/collection';
        service.catsAPI= new restAPI(catsPath,service.categories);
        //=== END CATEGORY API ===//

        //===== CATEGORY INVENTORY API =====//
        var catsInvPath='/api/collectionInventory';
        service.catsInvAPI= new restAPI(catsInvPath,service.categoriesInv);
        //=== END CATEGORY API ===//

        //===== INVENTORY API =====//
        var inventoryPath = '/api/inventory';
        service.inventoryApi = new restAPI(inventoryPath, service.inventories);

        //=== TRANSLATION API ===//
        var transPath='/api/translation';
        service.transAPI= new restAPI(transPath,service.translations);

        //=== END TRANSLATION API ===//


        //===DECLARE FUNCTION CONSTRUCTOR===//
        function restAPI(apiPath,getOutput) {
          this.get = function(query){
            var path = query || apiPath;
            return $http.get(path)
                       .then(function(response){
                         console.log({response:response});
                         getOutput=response.data;
                         return getOutput;
                       },function(error){
                         //on error
                         warning(error.statusText);
                         return error;
                       });
                     };
          this.post= function(obj){
            var body=angular.toJson(obj);
            return $http.post(apiPath,body)
                          .then(function(response){
                              console.log({response:response});
                              return response;
                            }, function(error){
                              console.log({error:error});
                              warning(error.statusText);
                              return error;
                            });
                          };
          this.put= function(id,obj){
            var body=angular.toJson(obj);
            return $http.post(apiPath + '/'+ id +'/edit',body)
                          .then(function(response){
                              console.log({response:response});
                              return response;
                            }, function(error){
                              console.log({error:error});
                              warning(error.statusText);
                              return error;
                            });
          };
          this.delete= function(id){
            return $http.delete(apiPath + '/'+ id +'/delete')
                          .then(function(response){
                              console.log({response:response});
                              return response;
                            }, function(error){
                              console.log({error:error});
                              warning(error.statusText);
                              return error;
                            });
          };
        }


        //=== NOTIFICATION function ===//
        function warning(message,number,unit,status){
          return hxNotificationService.showNotif(5000,message,number,unit,status);
        }
    }
})();

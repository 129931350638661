(function(){
    "use strict";
    angular.module('hxPOSAdmin')
        .controller('simpleStatisticsController',simpleStatisticsController);

    simpleStatisticsController.$inject=['$http','hxIsProcessingService','$scope'];
    function simpleStatisticsController($http,hxIsProcessingService, $scope){
       console.log('statisticsController ok');

       var $ctrl=this;

         //New displayObj//
         $ctrl.displayObjByTime={
           listProduct:[],
           totalPrice:0,
         };

         $ctrl.displayObjByTime_cash={
          listProduct:[],
          totalPrice:0,
        };

        $ctrl.displayObjByTime_credit={
          listProduct:[],
          totalPrice:0,
        };

         $ctrl.displayObjByTime2={
          listProduct:[],
          totalPrice:0,
        };

         $ctrl.datasetOverride = {
           backgroundColor: "rgba( 0,203,254,0.3)"
         };

       //Get statistic in new version//
       $ctrl.searchByDay=function(startDate,endDate){
         var startCreatedTime=new Date(startDate.setHours(0,0,0)).toISOString();
         var endCreatedTime=new Date(endDate.setHours(23,59,59)).toISOString();
         var appPaymentApiPath="/api";

         console.log(startCreatedTime);
         console.log(endCreatedTime);

         $scope.$emit('is_Processing');
         $http.get(appPaymentApiPath+'/combinedOrder/revenueStatistic/?status=1&startCreatedTime='+startCreatedTime+'&endCreatedTime='+
             endCreatedTime).then(function(response){
             "use strict";

             //Pass response data to list//
            console.log({responseData:response.data});
             $scope.$emit('done_Processing');
             $ctrl.displayObjByTime.startTime=startCreatedTime;
             $ctrl.displayObjByTime.endTime=endCreatedTime;

             //Change key in respone data//
             for (var i=0; i<response.data.length; i++){
                response.data[i].productName=response.data[i]['_id'];
                delete response.data[i]._id;
             }

             //Sort response data//
             response.data.sort(function(a,b){
              return a.productName.localeCompare(b.productName);
             });
            console.log(response.data);

             $ctrl.displayObjByTime.listProduct=response.data;
             $ctrl.displayObjByTime.totalPrice=0;
             for (i=0; i<$ctrl.displayObjByTime.listProduct.length; i++){
               $ctrl.displayObjByTime.totalPrice+=$ctrl.displayObjByTime.listProduct[i].revenue;
             }

             //PREPARE DATA FOR CHART//
             $ctrl.Data=$ctrl.displayObjByTime.listProduct.map(function(obj){
               return obj.revenue;
             });
             $ctrl.Labels=$ctrl.displayObjByTime.listProduct.map(function(obj){
               return obj.productName;
             });
             $ctrl.ChartOptions={responsive: true};
             console.log($ctrl.Data);

           });
           console.log({displayObj:$ctrl.displayObjByTime});

          $http.get(appPaymentApiPath+'/combinedOrder/TTStatistic/?status=5&startCreatedTime='+startCreatedTime+'&endCreatedTime='+
             endCreatedTime).then(function(response){

             //Pass response data to list//
             console.log({responseData:response.data});
             $scope.$emit('done_Processing');
             $ctrl.displayObjByTime2.startTime=startCreatedTime;
             $ctrl.displayObjByTime2.endTime=endCreatedTime;

             //Change key in respone data//
             for (var i=0; i<response.data.length; i++){
                response.data[i].productName=response.data[i]['_id'];
                delete response.data[i]._id;
             }

             //Sort response data//
             response.data.sort(function(a,b){
              return a.productName.localeCompare(b.productName);
             });
             console.log(response.data);

             $ctrl.displayObjByTime2.listProduct=response.data;
             $ctrl.displayObjByTime2.totalPrice=0;
             for (i=0; i<$ctrl.displayObjByTime2.listProduct.length; i++){
               $ctrl.displayObjByTime2.totalPrice+=$ctrl.displayObjByTime2.listProduct[i].revenue;
             }

           });
           console.log({displayObj:$ctrl.displayObjByTime2});

           //__________________TEST NEW API CASH________________________
           $http.get(appPaymentApiPath+'/combinedOrder/revenueStatisticByPayment/?status=1&startCreatedTime='+startCreatedTime+'&endCreatedTime='+
           endCreatedTime+'&paymentMethod=0').then(function(response){
           "use strict";
           //Pass response data to list//
           $scope.$emit('done_Processing');
           $ctrl.displayObjByTime_cash.startTime=startCreatedTime;
           $ctrl.displayObjByTime_cash.endTime=endCreatedTime;

           //Change key in respone data//
           for (var i=0; i<response.data.length; i++){
              response.data[i].productName=response.data[i]['_id'];
              delete response.data[i]._id;
           }

           //Sort response data//
           response.data.sort(function(a,b){
            return a.productName.localeCompare(b.productName);
           });

           $ctrl.displayObjByTime_cash.listProduct=response.data;
           $ctrl.displayObjByTime_cash.totalPrice=0;
           for (i=0; i<$ctrl.displayObjByTime_cash.listProduct.length; i++){
             $ctrl.displayObjByTime_cash.totalPrice+=$ctrl.displayObjByTime_cash.listProduct[i].revenue;
           }
         });
         console.log("displayObjectByCash", $ctrl.displayObjByTime_cash)
         // __________________END NEW API CASH________________________



         //__________________TEST NEW API CREDIT________________________
         $http.get(appPaymentApiPath+'/combinedOrder/revenueStatisticByPayment/?status=1&startCreatedTime='+startCreatedTime+'&endCreatedTime='+
         endCreatedTime+'&paymentMethod=1').then(function(response){
         "use strict";
         //Pass response data to list//
         $scope.$emit('done_Processing');
         $ctrl.displayObjByTime_credit.startTime=startCreatedTime;
         $ctrl.displayObjByTime_credit.endTime=endCreatedTime;

         //Change key in respone data//
         for (var i=0; i<response.data.length; i++){
            response.data[i].productName=response.data[i]['_id'];
            delete response.data[i]._id;
         }

         //Sort response data//
         response.data.sort(function(a,b){
          return a.productName.localeCompare(b.productName);
         });

         $ctrl.displayObjByTime_credit.listProduct=response.data;
         $ctrl.displayObjByTime_credit.totalPrice=0;
         for (i=0; i<$ctrl.displayObjByTime_credit.listProduct.length; i++){
           $ctrl.displayObjByTime_credit.totalPrice+=$ctrl.displayObjByTime_credit.listProduct[i].revenue;
         }

         //PREPARE DATA FOR CHART//
         $ctrl.Data=$ctrl.displayObjByTime_credit.listProduct.map(function(obj){
           return obj.revenue;
         });
         $ctrl.Labels=$ctrl.displayObjByTime_credit.listProduct.map(function(obj){
           return obj.productName;
         });
         $ctrl.ChartOptions={responsive: true};
         console.log($ctrl.Data);
         });
         console.log("displayObjectByCredit", $ctrl.displayObjByTime_credit)
         //__________________END API CREDIT________________________


        return $ctrl.displayObjByTime, $ctrl.displayObjByTime2, $ctrl.displayObjByTime_cash, $ctrl.displayObjByTime_credit;
       };

        $ctrl.printStatistic=function(){
          console.log('Send data to printer!');
          printerService.sendToPrinter('http://192.168.86.33:8081/api/star/printStatistic',$ctrl.displayObjByTime);
        };

        $ctrl.getTotalRevenueByDay=function(){
          var appPaymentApiPath="/api";
          console.log('Begin get total Revenue by Day');
          $scope.$emit('is_Processing');
          $http.get(appPaymentApiPath+'/combinedOrder/?status=1&startCreatedTime='+
            $ctrl.displayObjByTime.startTime+'&endCreatedTime='+
            $ctrl.displayObjByTime.endTime).then(function(response){
              "use strict";

              //Pass response data to list//
              console.log({responseData:response.data});

              // Get array with time and totalPrice //
              $ctrl.bigData=response.data.map(function(obj){
                 var newObj={};
                 newObj.time=new Date(obj.createdTime).toDateString();
                 newObj.priceList=obj.listProduct.map(function(e){
                   return (e.quantity*e.price);
                 });
                 newObj.totalPrice=newObj.priceList.reduce(function(a,b){
                   return a+b;
                 },0);
                 return newObj;
              });

              console.log($ctrl.bigData);

              // Get unique time and it's totalPrice //
              $ctrl.chartData=$ctrl.bigData.reduce(function(r,o){
                  if (r[o.time]) {
                    r[o.time] += Number(o.totalPrice);
                  } else {
                      r[o.time]=Number(o.totalPrice);
                  }
                  return r;
              },{});

              console.log($ctrl.chartData);
              $scope.$emit('done_Processing');
              //Prepare data for display//
              $ctrl.LabelsByDay=Object.keys($ctrl.chartData).reverse();
              $ctrl.DataByDay=Object.values($ctrl.chartData).reverse();
              console.log($ctrl.LabelsByDay);
              console.log($ctrl.DataByDay);
              $ctrl.ChartOptions={responsive: true};

            });
        };
      }
})();

(function () {
    "use strict";

    angular.module('hxAdminComponents')
        .component('hxSearchWithRef', {
            templateUrl: 'src/hxAdminComponents/hxSearchWithRef.html',
            controller:hxSearchWithRefController,
            controllerAs:'$ctrl',
            bindings:{
              inData:'<',
              selectedProducts:'=',
            }
        });

    hxSearchWithRefController.$inject = ['$element'];
    function hxSearchWithRefController($element){
        console.log('hxSearchWithRefController component ok !');
        var $ctrl = this;

        // $ctrl.selectedProducts=$ctrl.onSelected;
        // $ctrl.inData=[
        //   {productName:"Pho bo"},
        //   {productName:"Pho ga"},
        //   {productName:"Banh my xa xiu"},
        //   {productName:"Banh my gio"}
        // ];

        $ctrl.$onInit=function(){};

        $ctrl.add=function(id){
          console.log("product adding");
          //CHECK IF THERE IS DATA//
          if ($ctrl.outObj){
            console.log({outPut:$ctrl.outObj});
            $ctrl.onAdd({obj:$ctrl.outObj});
          }
        };

        $ctrl.clearSearchTerm = function() {
          $ctrl.searchTerm = '';
        };

        $ctrl.remove=function(id){
          console.log('On removing from list!');
          $ctrl.selectedProducts.splice(id,1);
        };

        $element.find('input').on('keydown', function(ev) {
          ev.stopPropagation();
        });
    }
})();

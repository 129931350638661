(function() {
    'use strict';

    angular.module('hxPOSAdmin')
          .controller('adminInvCtrl', adminInvCtrl);

    adminInvCtrl.$inject=['$http','$scope','$state','AdminService','products', 'category', 'stocks'];
    function adminInvCtrl($http, $scope, $state, AdminService, products, category, stocks){
      var $ctrl=this;
      var service=AdminService;
      
      $ctrl.inProducts = products;
      $ctrl.inCats =category;
      $ctrl.stocks = stocks;

      console.log('adminInvCtrl OK!');
      
      //Product management
      $ctrl.productCreate=function(){
        console.log('Create new Inventory product');
        $state.go('admin.productInvAdd');
      };

      $ctrl.productUpdate=function(id){
          console.log('Update product');
          console.log('Update object ID: '+id);
          console.log($ctrl.inProducts[id]);
          service.editInvProduct=$ctrl.inProducts[id];
          $state.go('admin.productInvEdit');
      };

      //Categories management
      $ctrl.catCreate=function(obj){
        console.log('Go to create category state');
        $state.go('admin.categoryInvAdd');
        //TODO: Call service to create new product;
      };

      $ctrl.catUpdate=function(id,obj){
          console.log('Update product');
          console.log('Update object ID: '+id);
          console.log($ctrl.inCats[id]);
          service.editInvCategory=$ctrl.inCats[id];
          $state.go('admin.categoryInvEdit');
        //TODO: Call service to update product;
      };

      $ctrl.catRemove=function(id,obj){
          console.log('Update product');
          console.log('Remove object ID: '+id);
        //TODO: Call service to update product;
      };

      $ctrl.productTranslate=function(id){
        service.quickTrans=$ctrl.inProducts[id].productName;
        $state.go('admin.translationInv');
      };

      $ctrl.catTranslate=function(id){
        service.quickTrans=$ctrl.inCats[id].categoryName;
        $state.go('admin.translationInvcats');
      };

      //Stock edit
      $ctrl.stockEdit = false;
      $ctrl.toggleStockEdit=function(){
        if ($ctrl.stockEdit){
            $ctrl.stockEdit = false;
        } else{
            $ctrl.stockEdit = true;
        }
        console.log($ctrl.stockEdit);
      };

      $ctrl.updateStock=function(obj){
        console.log(obj);
        var submissionDate = new Date();
        var sendObj = {
            "staff": "Admin", //Default
            "categoryName": "Others", //Default
            "productName": obj.productName,
            "InOut": "IN",
            "quantity": obj.newQuantity - obj.quantity ,
            "date": submissionDate.getTime()
        };
        console.log(sendObj);

        if (!isNaN(sendObj.quantity)){
            $scope.$emit('is_Processing');
            service.inventoryApi.post(sendObj).then(function(response){
                $scope.$emit('done_Processing');
                $state.reload();
            });
        }
      };

      $ctrl.updateAllStock= function(){
        for (var i = 0; i < $ctrl.stocks.length; i ++ ) {
            $ctrl.updateStock($ctrl.stocks[i]);
        }
      };
      
      // statistic
        $ctrl.displayObjByStaff = {}

        //Get statistic in new version//
        $ctrl.searchByDay=function(startDate,endDate){
            var startCreatedTime=new Date(startDate.setHours(0,0,0)).toISOString();
            var endCreatedTime=new Date(endDate.setHours(23,59,59)).toISOString();
            var appPaymentApiPath="/api";

            $scope.$emit('is_Processing');
            $http.get(appPaymentApiPath+'/inventory/?startCreatedTime='+startCreatedTime+'&endCreatedTime='+
                endCreatedTime).then(function(response){

                //Pass response data to list//
                console.log({responseData:response.data});
                $scope.$emit('done_Processing');
                $ctrl.displayObjByStaff = convertResponseToDispStaffObj(response.data)

                // display Object by Store
                $ctrl.displayObjByStore = convertResponseToDispStoreObj(response.data)

            });
            console.log({displayObj:$ctrl.displayObjByStaff});
        };

        // convert Response data to Display statistic by Staff
        function convertResponseToDispStaffObj(response) {
            var convertdata = {}

            for (var i = 0; i < response.length; i ++ ) {
                var value = response[i]
                var staffNameId;
                var InOut;
                var quantity;
                var productName;
                var date;

                if (value.hasOwnProperty('staff')) {
                    staffNameId = value['staff']
                }

                if (value.hasOwnProperty('InOut')) {
                    InOut = value['InOut']
                }

                if (value.hasOwnProperty('quantity')) {
                    quantity = value['quantity']
                }

                if (value.hasOwnProperty('productName')) {
                    productName = value['productName']
                }

                if (value.hasOwnProperty('date')) {
                    date = value['date']
                }

                var detail = {}
                detail['productName'] = productName
                detail['quantity'] = quantity
                detail['date'] = date

                if (staffNameId in convertdata) {
                    // existed staff key

                    if (InOut == 'IN') {
                        convertdata[staffNameId]['IN'].push(detail)
                    } else {
                        convertdata[staffNameId]['OUT'].push(detail)
                    }
                } else {
                    // new staff key
                    convertdata[staffNameId] = {}
                    convertdata[staffNameId]['IN'] = []
                    convertdata[staffNameId]['OUT'] = []

                    if (InOut == 'IN') {
                        convertdata[staffNameId]['IN'].push(detail)
                    } else {
                        convertdata[staffNameId]['OUT'].push(detail)
                    }
                }
            }

            console.log(convertdata)

            return convertdata;
        }

        // convert to display object by store
        function convertResponseToDispStoreObj(response) {
            var convertdata = {}

            for (var i = 0; i < response.length; i++) {
                var value = response[i];
                var InOut = value['InOut'];
                var store = value['OutrestaurantName'];
                var quantity = value['quantity'];
                var productName = value['productName'];

                if (InOut == 'OUT') {
                    if (store in convertdata) {
                        if (productName in convertdata[store]) {
                            convertdata[store][productName] = convertdata[store][productName] + quantity
                        } else {
                            convertdata[store][productName] = quantity
                        }
                    } else {
                        convertdata[store] = {}
                        convertdata[store][productName] = quantity
                    }
                }
            }

            console.log(convertdata)

            return convertdata;
        }
    }
})();

/**
 * Created by Trung pham on 14/10/19.
 */
(function(){
    "use strict";

    angular.module('hxAdminComponents')
        .component('statisticTable',{
            templateUrl:'src/hxAdminComponents/statisticTable.html',
            controller:statisticTableCtrl,
            bindings:{
                input:'<'
            }
        });

    statisticTableCtrl.$inject=['$scope'];
    function statisticTableCtrl($scope){
        console.log("statisticDisplay component ok!");
        var $ctrl=this;
        $ctrl.displayData = {};
        $ctrl.total = {
            takeout_quantity: 0,
            takeout_revenue: 0,
            eatin_quantity: 0,
            eatin_revenue: 0,
            
            // For seprate cash & credit for order type 
            eatin_cash:0, 
            eatin_credit:0,
            takeout_cash:0,
            takeout_credit:0,
        }
        $ctrl.$onInit=function(){
        };

        // Old logic for get all of order list 
        $scope.$watch("$ctrl.input[0]", function(){
            //Convert to table format
            $ctrl.input[0].forEach(function(e){
                if (!(e.productName in $ctrl.displayData)){
                    console.log("Found new!")
                     $ctrl.displayData[e.productName] = {
                         takeout:{
                             quantity: 0,
                             revenue: 0
                         },
                         eatin:{
                             quantity: 0,
                             revenue: 0
                         }
                     }
                }
 
                if (e.seatNumber > 0){
                     $ctrl.displayData[e.productName].eatin.quantity = e.quantity;
                     $ctrl.displayData[e.productName].eatin.revenue = e.revenue;
                     $ctrl.total.eatin_quantity += e.quantity;
                     $ctrl.total.eatin_revenue += e.revenue;
                }
 
                if (e.seatNumber == 0){
                    $ctrl.displayData[e.productName].takeout.quantity = e.quantity;
                    $ctrl.displayData[e.productName].takeout.revenue = e.revenue;
                    $ctrl.total.takeout_quantity += e.quantity;
                    $ctrl.total.takeout_revenue += e.revenue;
                }
     
             });
             
        });

        // New logic for watch order by cash list to get all of order paid by cash 
        $scope.$watch("$ctrl.input[1]", function(){
            var eatinRevenueByCash = 0;
            var takeOutRevenueByCash = 0; 
         
            //Convert to table format
             $ctrl.input[1].forEach(function(e){
                 if (!(e.productName in $ctrl.displayData)){
                     console.log("Found new by cash!")
                      $ctrl.displayData[e.productName] = {
                          takeout:{
                              quantity: 0,
                              revenue: 0
                          },
                          eatin:{
                              quantity: 0,
                              revenue: 0
                          }
                      }
                 }
  
                 if (e.seatNumber > 0){
                     eatinRevenueByCash += e.revenue;
                 }
  
                 if (e.seatNumber == 0){
                     takeOutRevenueByCash += e.revenue;
                 }
      
              });
              $ctrl.total.takeout_cash = takeOutRevenueByCash              
              $ctrl.total.eatin_cash = eatinRevenueByCash;
        });
        
        // New logic for watch order by credit list to get all of orders paid by credit 
        $scope.$watch("$ctrl.input[2]", function(){
            var eatinRevenueByCredit = 0;
            var takeOutRevenueByCredit = 0;
            //Convert to table format
              $ctrl.input[2].forEach(function(e){
                if (!(e.productName in $ctrl.displayData)){
                    console.log("Found new by credit!")
                     $ctrl.displayData[e.productName] = {
                         takeout:{
                             quantity: 0,
                             revenue: 0
                         },
                         eatin:{
                             quantity: 0,
                             revenue: 0
                         }
                     }
                }
 
                if (e.seatNumber > 0){
                    eatinRevenueByCredit += e.revenue;
                }
 
                if (e.seatNumber == 0){
                    takeOutRevenueByCredit += e.revenue;
                }
     
             });

             $ctrl.total.takeout_credit = takeOutRevenueByCredit;
             $ctrl.total.eatin_credit = eatinRevenueByCredit;

        });
    }
})();

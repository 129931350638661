(function () {
    "use strict";

    angular.module('hxStaffLanguageModule')
        .component('hxDisplayTranslation', {
            templateUrl: 'src/hxStaffLanguage/hxDisplayTranslation.html',
            controller:hxDisplayTranslationController,
            controllerAs:'$ctrl',
            bindings:{
              listTranslation:'<',
              onEdit:'&',
              onRemove:'&'
            }
        });

    hxDisplayTranslationController.$inject = [];
    function hxDisplayTranslationController(){
        console.log('hxDisplayTranslationController component ok !');
        var $ctrl = this;

        $ctrl.$onInit=function(){

        };

        $ctrl.edit=function(id){
          //CHECK IF THERE IS DATA//
          console.log('Begin create translation');
          console.log({outPut:$ctrl.listTranslation[id]});
          $ctrl.onEdit({key:$ctrl.listTranslation[id].key});
        };

        $ctrl.remove=function(id){
          //CHECK IF THERE IS DATA//
          console.log('Begin create translation');
          console.log({outPut:$ctrl.listTranslation[id]});
          $ctrl.onRemove({id:$ctrl.listTranslation[id].id});
        };
    }
})();

(function(){
    "use strict";

    /**
    * @module hxNotificationModule
    * @description
    * This module is in charge of display various types of notification.
    */

    angular.module('hxNotificationModule',[]);

})();

(function () {
    "use strict";

    angular.module('hxAdminComponents')
        .component('hxListDisplay', {
            templateUrl: 'src/hxAdminComponents/hxListDisplay.html',
            controller:hxListDisplayController,
            controllerAs:'$ctrl',
            bindings:{
              inObj:'<',
              onCreate:'&',
              onSelect:'&',
              onRemove:'&',
              onTranslate:'&',
              query:'<'
            }
        });

    hxListDisplayController.$inject = [];
    function hxListDisplayController(){
        console.log('hxListDisplayController component ok !');
        var $ctrl = this;
        $ctrl.color='';

        $ctrl.$onInit=function(){

        };

        $ctrl.create=function(id){
            console.log('Creating obj');
            $ctrl.onCreate();
        };

        $ctrl.edit=function(id){
            console.log('Editing obj');
            $ctrl.onSelect({id:id});
        };

        $ctrl.remove=function(id){
            $ctrl.onRemove({id:id});
        };

        $ctrl.translate=function(id){
          $ctrl.onTranslate({id:id});
        };
    }
})();

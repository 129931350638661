(function(){
    "use strict";
    /**
     * @memberof hxLogInOut
     * @ngdoc component
     * @name hxLoginForm
     * @description
     * This component provide login Form to process Authentication using auth0.
     *
     * @attr {Expression} nextState (optional)
     * provide destination of the transition after successful login.Default no transition
     * @example <hx-login-form next-state="admin.list"></hx-login-form>
     */
    angular.module('hxLogInOutModule')
        .component('hxLoginForm', {
            templateUrl: 'src/hxLogInOut/hxLoginForm.html',
            controller: hxLoginFormCtrl,
            bindings: {
                nextState: '@?',
                outObj:'&'
            }
        } );
    hxLoginFormCtrl.$inject=['hxAuthService','$scope','$mdToast','$state'];
    function hxLoginFormCtrl (hxAuthService,$scope,$mdToast,$state){
       var  $ctrl =this;
       //$ctrl.test="ENTER_YOUR_ID";
        $ctrl.isProcessing=false;

        $ctrl.submit= function(){
            if(!$ctrl.isProcessing){
                hxAuthService.logIn($ctrl.email,$ctrl.pwd);
            }

            $ctrl.isProcessing=true;
        };

        $ctrl.listener=$scope.$on('HX:logInSuccess',function(){
            console.log('in Ctrl login');

           $scope.$apply(function(){
               $ctrl.isProcessing=false;
           });// why we need this // only use apply at controller level of the state
            console.log({beforeIf:$ctrl.nextState});
            if($ctrl.nextState){
                console.log($ctrl.nextState);
                $state.go($ctrl.nextState);
            }
        });
        $ctrl.listener1=$scope.$on('HX:logInOutError',function(e,data){
            $scope.$apply(function(){
                $ctrl.isProcessing=false;
            });
            console.log(data);
            $mdToast.show(
                $mdToast.simple()
                    .textContent(data.message.description)
                    .position('top')
                    .hideDelay(4000)
            );

        });
        $ctrl.$onDestroy=function(){
            $ctrl.isProcessing=false;
            $ctrl.listener();
            $ctrl.listener1();
        }
    }

})();